import * as React from "react";
import { useState, useCallback } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import Icon from "@app/components/icon";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import QKModal from "@app/components/modal";
import { Platform, ScrollView, View } from "react-native";
import { QuestSectionHeader } from "@app/components/screen/quest/questSectionHeader";
import Button from "@app/components/questkit/button";
import { useModal } from "@app/components/modal/ModalManager";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { Analytics } from "@app/analytics";
import { useHover } from "@app/util/useHover";
import BasePressable, {
  type OnPress,
} from "@app/components/questkit/BasePressable";
import { colors } from "@app/themes/Colors";

interface ChooseQuestStartTriggerProps {
  onCancelChangeQuestType: OnPress;
  onStartTypeSelected: (startType: QuestStartTriggerType) => Promise<void>;
  currentType: QuestStartTriggerType;
}
const orderedQuestTypes: QuestStartTriggerType[] = [
  "BASIC",
  "SCHEDULE",
  "PUBLIC",
  "KIOSK",
];
export const ChooseQuestStartTriggerView: React.FC<
  ChooseQuestStartTriggerProps
> = (props) => {
  const [currentType, setCurrentType] = useState(props.currentType);

  const onContinue = useCallback(
    () => props.onStartTypeSelected(currentType),
    [currentType, props]
  );
  return (
    <ChooseQuestTypeStartViewContainer testID="select-start-type-view">
      <SupportText size="medium">
        How do you plan to use this Quest?
      </SupportText>
      <RadioButtonGroup>
        {orderedQuestTypes.map((startType, index) => {
          return (
            <>
              {index !== 0 ? <Divider /> : null}
              <StartTypeListItem
                key={startType}
                startType={startType}
                onQuestTypeSelected={setCurrentType}
                isSelected={currentType === startType}
              />
            </>
          );
        })}
      </RadioButtonGroup>
      <Button
        title={"Continue"}
        onPress={onContinue}
        testID="continue-with-start-type"
      />
    </ChooseQuestTypeStartViewContainer>
  );
};

const SupportText = styled(Text)`
  text-align: center;
  margin-bottom: 50px;
`;
const Divider = styled.View`
  margin-horizontal: 24px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #d9d9d9;
`;
const ChooseQuestTypeStartViewContainer = styled.View`
  margin-vertical: 40px;
`;

type StartTypeListItemProps = {
  startType: QuestStartTriggerType;
  onQuestTypeSelected: (startType: QuestStartTriggerType) => void;
  isSelected: boolean;
};
export const StartTypeListItem: React.FC<StartTypeListItemProps> = ({
  startType,
  onQuestTypeSelected,
  isSelected,
}) => {
  const { name, shortDescription } = QUEST_TYPES[startType];
  const { hoverProps: cardHoverProps } = useHover();
  const { hoverProps: expandIconHoverProps } = useHover();

  const { openModal, closeModal } = useModal(({ showModal, setShowModal }) => {
    return (
      <QKModal
        title={`Select Quest Mode`}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <QuestTypeModalView startType={startType} closeModal={closeModal} />
      </QKModal>
    );
  });

  const onPress = useCallback(() => {
    onQuestTypeSelected(startType);
  }, [startType, onQuestTypeSelected]);

  const onExpand = useCallback(() => {
    Analytics.trackEvent("Expand Start Type", { startType });
    openModal();
  }, [startType, openModal]);

  return (
    <>
      <StartTypeContainer
        onPress={onPress}
        testID={`quest-type-${startType}`}
        {...cardHoverProps}
      >
        <StartTypeOptionContent>
          <OptionHeaderRow>
            <OptionHeader>
              <RadioButtonCircle isSelected={isSelected} />
              <QuestTypeName>{name}</QuestTypeName>
            </OptionHeader>
            <ExpandIconAligner onPress={onExpand} {...expandIconHoverProps}>
              <Icon icon="expand" size={32} container={"COLLAPSED"} />
            </ExpandIconAligner>
          </OptionHeaderRow>
          <QuestTypeDescription>{shortDescription}</QuestTypeDescription>
          {/*<StartTypeOptionUseCasesSection>*/}
          {/*  {useCases.map((useCase) => {*/}
          {/*    return (*/}
          {/*      <QuestTypeUseCasePill key={`${name}-${useCase}`}>*/}
          {/*        <QuestTypeUseCasePillText>{useCase}</QuestTypeUseCasePillText>*/}
          {/*      </QuestTypeUseCasePill>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</StartTypeOptionUseCasesSection>*/}
        </StartTypeOptionContent>
      </StartTypeContainer>
    </>
  );
};
const ExpandIconAligner = styled(BasePressable)<{
  isHovered: boolean;
}>`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? "#F5EEFE" : theme.background};
  transition: background-color 0.15s ease-in-out;
`;

const OptionHeader = styled.View`
  flex-direction: row;
  align-items: center;
`;
const OptionHeaderRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const QuestTypeName = styled(Text).attrs({ size: "mediumBold" })`
  ${Platform.OS === "web" ? "user-select: none" : ""}
  margin-left: 8px;
`;

const QuestTypeDescription = styled(Text).attrs({
  size: "small",
})`
  ${Platform.OS === "web" ? "user-select: none" : ""}
  margin-top: 8px;
  margin-left: 32px;
`;

const RadioButtonGroup = styled.View`
  max-width: 540px;
  width: 100%;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid;
  border-radius: 20px;
  overflow: hidden;
`;

interface RadioButtonCircleProps {
  isSelected: boolean;
}
const RadioButtonCircle: React.FC<RadioButtonCircleProps> = ({
  isSelected,
}) => {
  return (
    <RadioButtonCircleContainer>
      <RadioButtonCircleInner isSelected={isSelected} />
    </RadioButtonCircleContainer>
  );
};
const RadioButtonCircleContainer = styled.View`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid ${colors.primary700};
  justify-content: center;
  align-items: center;
`;
const RadioButtonCircleInner = styled.View<RadioButtonCircleProps>`
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: ${colors.primary700}
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
`;

const QuestTypeModalUseCasesSection = styled.View`
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const QuestTypeUseCasePillText = styled(Text).attrs({
  size: "small",
  numberOfLines: 1,
})`
  color: ${({ theme }) => theme.background};
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
const QuestTypeUseCasePill = styled.View`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primary};
  margin-right: 10px;
  margin-bottom: 10px;
  height: 30px;
`;

const StartTypeOptionContent = styled.View`
  flex: 1;
  justify-content: center;
`;
const StartTypeContainer = styled(BasePressable)<{
  isHovered: boolean;
}>`
  flex-direction: row;
  background-color: ${({ theme }) => theme.background};
  max-width: 540px;
  width: 100%;
  min-height: 120px;
  padding: 24px;
`;

type QuestTypeModalViewProps = {
  startType: QuestStartTriggerType;
  closeModal: () => void;
};
export const QuestTypeModalView: React.FC<QuestTypeModalViewProps> = ({
  startType,
  closeModal,
}) => {
  const { icon, useCases, features, name, longDescription } =
    QUEST_TYPES[startType];

  return (
    <QuestTypeModalViewContainer>
      <ScrollView>
        <View onStartShouldSetResponder={() => true}>
          <QuestTypeIconModalContainer>
            <Icon icon={icon} size={80} />
          </QuestTypeIconModalContainer>
          <QuestTypeNameInModal>{name}</QuestTypeNameInModal>
          <QuestTypeDescriptionInModal>
            {longDescription}
          </QuestTypeDescriptionInModal>
          <FeaturesSectionHeader text="Features" />
          <FeaturesListContainer>
            {features.map((feature, index) => {
              return (
                <FeatureListItemRow key={`${name}-feature-${index}`}>
                  <FeatureListBulletPointAligner>
                    <FeatureListBulletPointIcon icon="star-filled-in" />
                  </FeatureListBulletPointAligner>

                  <FeatureListText>{feature}</FeatureListText>
                </FeatureListItemRow>
              );
            })}
          </FeaturesListContainer>
          <FeaturesSectionHeader text="Common Use Cases" />
          <QuestTypeModalUseCasesSection>
            {useCases.map((useCase) => {
              return (
                <QuestTypeUseCasePill key={`${name}-${useCase}`}>
                  <QuestTypeUseCasePillText>{useCase}</QuestTypeUseCasePillText>
                </QuestTypeUseCasePill>
              );
            })}
          </QuestTypeModalUseCasesSection>
        </View>
      </ScrollView>
      <CloseModalButton title="Close" onPress={closeModal} />
    </QuestTypeModalViewContainer>
  );
};

const CloseModalButton = styled(Button)`
  margin-top: 20px;
`;
const FeatureListBulletPointIcon = styled(Icon)``;
const FeaturesListContainer = styled.View`
  margin-bottom: 30px;
`;

const FeatureListItemRow = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FeatureListText = styled(Text).attrs({ size: "small" })``;
const FeatureListBulletPointAligner = styled.View`
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 19px;
  margin-left: -1px;
  margin-right: 5px;
`;

const FeaturesSectionHeader = styled(QuestSectionHeader)`
  margin-horizontal: 0;
`;

const QuestTypeNameInModal = styled(Text).attrs({ size: "large" })`
  margin-bottom: 20px;
  text-align: center;
`;

const QuestTypeDescriptionInModal = styled(Text).attrs({
  size: "medium",
})`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
`;

const QuestTypeIconModalContainer = styled.View`
  align-items: center;
`;
const QuestTypeModalViewContainer = styled.View`
  flex-shrink: 1;
  padding: 0 20px 20px 20px;
`;
